@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .c-mega-menu__outer-wrap {
        display: block !important;
    }
}

@media
all and (min-width: 721px) and (-ms-high-contrast: active),
all and (min-width: 721px) and (-ms-high-contrast: none) {
    .o-button-container--nav .o-button-container__button {
        width: auto !important;
    }
}
.other-tools-btn {
    background-color: white;
    border: none;
}

.other-tools-icon-btn {
    font-size: 7em;
}

.flatpickr-monthSelect-month {
    width: 40%
}

.flatpickr-monthSelect-month.selected {
    background-color: #004666;
}

.upload-dss {
    height: 100%;
}